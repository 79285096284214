import React from 'react';
import { Box, useRadio, UseRadioProps, useStyleConfig } from '@chakra-ui/react';

export type RadioButtonProps = React.PropsWithChildren<UseRadioProps>;

export const RadioButton: React.FC<RadioButtonProps> = (
  props
): React.ReactElement => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  const styles = useStyleConfig('RadioButton');

  return (
    <Box as="label" flexGrow={1}>
      <input {...input} />
      <Box {...radio} __css={styles} aria-hidden="false">
        {props.children}
      </Box>
    </Box>
  );
};

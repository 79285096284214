import React, { useCallback } from 'react';
import {
  StepperCurrencyInput,
  Flex,
  Heading,
  RadioButton,
  RadioButtonGroup,
  Skeleton,
  Slider,
  Spacer,
  Text,
  VisuallyHidden,
  useBreakpoint,
} from '@newday/core';
import { formatCurrency } from '../../utils';

const { minLoanAmount, maxLoanAmount } = {
  minLoanAmount: 1000,
  maxLoanAmount: 7500,
};

const availableLoanYears = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export interface LoanDetails {
  amount: number;
  durationYears: number;
}
interface BaseLoanSelectorProps {
  defaultLoanStep?: number;
  isLoaded?: boolean;
  asFlex?: boolean;
}
export interface UncontrolledLoanSelectorProps extends BaseLoanSelectorProps {
  defaultLoanDetails: LoanDetails;
  onChangeLoanDetails?: (loanDetails: Partial<LoanDetails>) => void;
}

export interface ControlledLoanSelectorProps extends BaseLoanSelectorProps {
  loanDetails: LoanDetails;
  onChangeLoanDetails: (loanDetails: Partial<LoanDetails>) => void;
}

export type LoanSelectorProps =
  | UncontrolledLoanSelectorProps
  | ControlledLoanSelectorProps;

export const LoanSelector: React.FC<LoanSelectorProps> = ({
  defaultLoanStep = 100,
  isLoaded = true,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const { onChangeLoanDetails } = props;

  const isControlled =
    'loanDetails' in props &&
    'onChangeLoanDetails' in props &&
    !('defaultLoanDetails' in props);

  const defaultLoanDetails = isControlled
    ? { amount: 0, durationYears: 0 }
    : props.defaultLoanDetails;

  const [loanDetailsState, setLoanDetails] = React.useState(defaultLoanDetails);

  const loanDetails = isControlled ? props.loanDetails : loanDetailsState;

  const handleChange = useCallback(
    (loanDetailPart: Partial<LoanDetails>) => {
      const amount =
        loanDetailPart.amount || loanDetailPart.amount === 0
          ? loanDetailPart.amount
          : loanDetails.amount;

      const durationYears =
        loanDetailPart.durationYears || loanDetails.durationYears;

      const newLoanDetails = {
        amount,
        durationYears,
      };

      if (!isControlled) {
        setLoanDetails(newLoanDetails);
      }

      if (onChangeLoanDetails) {
        onChangeLoanDetails({
          amount: loanDetailPart.amount,
          durationYears: loanDetailPart.durationYears,
        });
      }
    },
    [isControlled, loanDetails, onChangeLoanDetails, setLoanDetails],
  );

  const handleAmountChange = React.useCallback(
    (amount: number | string) => {
      handleChange({ amount: Number(amount) });
    },
    [handleChange],
  );
  const handleAmountBlur = React.useCallback(
    (e) => {
      const amount = Number(e.target.value);
      if (amount > minLoanAmount && amount < maxLoanAmount) {
        e.preventDefault();
        handleChange({
          amount: Math.round(amount / defaultLoanStep) * defaultLoanStep,
        });
      }
    },
    [handleChange, defaultLoanStep],
  );

  const handleDurationYearsChange = React.useCallback(
    (durationYears: string) => {
      handleChange({ durationYears: Number(durationYears) });
    },
    [handleChange],
  );

  const amountComponentsProps = {
    min: minLoanAmount,
    max: maxLoanAmount,
    step: defaultLoanStep,
    value: loanDetails.amount,
    onChange: handleAmountChange,
  };

  return (
    <Flex
      w="full"
      flexDirection="column"
      bg="white"
      px={4}
      pt={4}
      pb={6}
      data-testid="loan-selector"
    >
      <Heading
        as="label"
        w="full"
        fontSize="md"
        fontWeight="semibold"
        lineHeight="base"
        alignItems="flex-start"
        mb={3}
        htmlFor="loan-input"
      >
        I want to borrow:
      </Heading>
      <Skeleton data-testid="skeleton" isLoaded={isLoaded}>
        <StepperCurrencyInput
          id="loan-input"
          focusInputOnChange={false}
          {...amountComponentsProps}
          onBlur={handleAmountBlur}
        />
        <Slider
          mt={6}
          focusThumbOnChange={false}
          {...amountComponentsProps}
          aria-label="amount"
        />
        <Flex>
          <Text fontSize="sm" as="label">
            <VisuallyHidden>(Minimum amount)</VisuallyHidden>
            {formatCurrency(amountComponentsProps.min)}
          </Text>
          <Spacer />
          <Text fontSize="sm" as="label">
            <VisuallyHidden>(Maximum amount)</VisuallyHidden>
            {formatCurrency(amountComponentsProps.max)}
          </Text>
        </Flex>
      </Skeleton>
      <Heading
        w="full"
        fontSize="md"
        fontWeight="semibold"
        lineHeight="base"
        alignItems="flex-start"
        mt={6}
        mb={3}
      >
        Years to repay over:
      </Heading>
      <Skeleton data-testid="skeleton" isLoaded={isLoaded}>
        <RadioButtonGroup
          radioButtonsPerRow={breakpoint === 'base' ? 3 : 5}
          onChange={handleDurationYearsChange}
          value={loanDetails.durationYears}
        >
          {({ getRadioButtonProps }) =>
            availableLoanYears.map(({ label, value }) => {
              const radio = getRadioButtonProps({ value });
              return (
                <RadioButton key={value} {...radio}>
                  {label}
                </RadioButton>
              );
            })
          }
        </RadioButtonGroup>
      </Skeleton>
    </Flex>
  );
};
